export default [
  {
    title: 'Gambling Squares',
    subtitle: 'heat maps of winning squares',
    date: 'Mar 17, 2023',
    url: 'squares',
    imageFilename: 'squares_ss.png',
  },
  {
    title: 'Dice Game Simulation',
    subtitle: 'a brute force analysis of Midnight',
    date: 'Jan 07, 2022',
    url: 'midnight',
    imageFilename: 'midnight_ss.png',
  },
  /* {
    title: 'COVID-19 in US',
    subtitle: 'a look at the growth of COVID-19 cases',
    date: 'Mar 29, 2020',
    url: 'covid',
    imageFilename: 'covid_ss.png',
  }, */
  {
    title: 'Weather in Boulder',
    subtitle: 'a look at the weather in 2019',
    date: 'Mar 28, 2020',
    url: 'weather',
    imageFilename: 'weather_ss.png',
  },
  {
    title: 'Songs over Time by BPM',
    subtitle: 'my music collection over time by bpm',
    date: 'Jul 10, 2017',
    url: 'bpm-3',
    imageFilename: 'bpm_visual_3_ss.png',
  },
  {
    title: 'Fantasy Basketball Ranks \'16',
    subtitle: 'top basketball players in fantasy scoring in 2016',
    date: 'Oct 21, 2016',
    url: '//jpecht.com/vis-old/bball_ranks_2016.html',
    imageFilename: 'bball_ranks_2016_ss.png',
  },
  {
    title: 'BPM Visual Revisited',
    subtitle: 'my music collection sorted by bpm',
    date: 'Sep 14, 2016',
    url: '//jpecht.com/vis-old/bpm_visual_2.html',
    imageFilename: 'bpm_visual_2_ss.png',
  },
  {
    title: 'DC Demographics',
    subtitle: 'breakdown of dc by demographic',
    date: 'Jun 18, 2015',
    url: '//jpecht.com/vis-old/dc_demographics.html',
    imageFilename: 'dc_demographics_ss.png',
  },
  {
    title: 'How Teams Do In March Madness',
    subtitle: 'tournament performance in the last 30 years',
    date: 'Mar 19, 2015',
    url: '//jpecht.com/vis-old/march_madness.html',
    imageFilename: 'march_madness_ss.png',
  },
  {
    title: 'Fantasy Football Revisited',
    subtitle: 'how well fantasy football players performed',
    date: 'Dec 25, 2014',
    url: '//jpecht.com/vis-old/ff_performance.html',
    imageFilename: 'ff_performance_ss.png',
  },
  {
    title: 'Facebook Friend Network',
    subtitle: 'force layout diagram of my facebook friends',
    date: 'Nov 21, 2014',
    url: '//jpecht.com/vis-old/fb_force.html',
    imageFilename: 'fb_force_ss.png',
  },
  {
    title: 'BPM Visual',
    subtitle: 'my music collection sorted by bpm',
    date: 'Nov 11, 2014',
    url: '//jpecht.com/vis-old/bpm_visual.html',
    imageFilename: 'bpm_visual_ss.png',
  },
  {
    title: 'DC Snowfall',
    subtitle: 'yearly snowfall in dc',
    date: 'Nov 10, 2014',
    url: '//jpecht.com/vis-old/dc_snowfall.html',
    imageFilename: 'dc_snowfall_ss.png',
  },
  {
    title: 'Fantasy Basketball Ranks',
    subtitle: 'the top basketball players in fantasy scoring',
    date: 'Oct 28, 2014',
    url: '//jpecht.com/vis-old/bball_ranks.html',
    imageFilename: 'bball_ranks_ss.png',
  },
  {
    title: 'TV Show Viewership',
    subtitle: 'a look at the ratings of recent popular tv shows',
    date: 'Oct 12, 2014',
    url: '//jpecht.com/vis-old/tv_shows.html',
    imageFilename: 'tv_shows_ss.png',
  },
  {
    title: 'Education Spending',
    subtitle: 'how much money is spent per student in the u.s.',
    date: 'Sep 16, 2014',
    url: '//jpecht.com/vis-old/education.html',
    imageFilename: 'education_ss.png',
  },
  {
    title: 'Fantasy Draft Picks Pt. 2',
    subtitle: 'a look at my league\'s draft picks',
    date: 'Sep 4, 2014',
    url: '//jpecht.com/vis-old/ff_draft_2.html',
    imageFilename: 'ff_2_ss.png',
  },
  {
    title: 'Fantasy Draft Picks',
    subtitle: 'average draft picks in fantasy football',
    date: 'Sep 4, 2014',
    url: '//jpecht.com/vis-old/ff_draft.html',
    imageFilename: 'ff_ss.png',
  },
  {
    title: 'Drought',
    subtitle: 'recent drought crisis in cali',
    date: 'Sep 2, 2014',
    url: '//jpecht.com/vis-old/drought.html',
    imageFilename: 'drought_ss.png',
  },
  {
    title: 'Population',
    subtitle: 'historical population in the u.s.',
    date: 'Aug 26, 2014',
    url: '//jpecht.com/vis-old/population.html',
    imageFilename: 'population_ss.png',
  },
  {
    title: 'Poverty Rates',
    subtitle: 'poverty in the u.s. by ethnicity',
    date: 'Aug 21, 2014',
    url: '//jpecht.com/vis-old/poverty.html',
    imageFilename: 'poverty_ss.png',
  },
  {
    title: 'Income Thresholds',
    subtitle: 'class division in the u.s.',
    date: 'Aug 19, 2014',
    url: '//jpecht.com/vis-old/income.html',
    imageFilename: 'income_ss.png',
  },
];
